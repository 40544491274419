import './styles/index.scss';
import Swiper from 'swiper';
import $ from 'jquery';
import IMask from 'imask';


$('#root').each(function () {
  const page = $(this);

  const $form = page.find('.js-form');
  const $formContent = page.find('.form__content');
  const $formSuccess = page.find('.form__success');

  const $link = page.find('.js-link');

  const $name = page.find('.js-name');
  const $question = page.find('.js-question');
  const phoneMask = IMask(
    document.getElementById('phone-mask'), {
      mask: '+{7}(000)000-00-00'
    });

  $link.on('click', (e) => {
    e.preventDefault();
    const $target = $(e.target).data('link');

    $('html, body').animate({
      scrollTop: $(`#${$target}`).offset().top
    }, 500);
  });
  $form.submit(e => {
    e.preventDefault();
    const nameValue = $name.val();
    const phoneValue = phoneMask.value;
    const questionValue = $question.val();

    if ((nameValue === "" || !nameValue) ||
      (phoneValue === "" || !phoneValue) ||
      (questionValue === "" || !questionValue)
    ) {
      console.log('wrong');
      return;
    }

    $.ajax('/api/send_email', {
      method: 'post',
      data: JSON.stringify({
        name: nameValue,
        phone: phoneValue,
        question: questionValue
      }),
      success: (e) => {
        $formContent.addClass('form__content_hidden');
        $formSuccess.addClass('form__success_visible');
      }
    })
  });


  page.find('.js-reviews-swiper').each(function () {
    const init = (() => {
      let swiper;
      let prevSlidesNumber;

      return () => {
        let slidesNumber = 1;

        if (slidesNumber !== prevSlidesNumber) {
          if (swiper) {
            swiper.destroy();
          }
          swiper = new Swiper(this, {
            direction: 'horizontal',
            keyboardControl: true,
            slidesPerView: 1,
            nested: true,
            clickable: true,
            pagination: {
              el: '.swiper-pagination',
              type: 'bullets',
              bulletClass: 'reviews__pagination-bullet',
              bulletActiveClass: 'reviews__pagination-bullet_active',
            }
          });

          prevSlidesNumber = slidesNumber;
        }
      }
    })();

    init();
    window.addEventListener('resize', init);
  });
});

